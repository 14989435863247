import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import * as styles from "../styles/blogList.module.css";

export default class BlogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const ShowMorePosts = () => {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 700);
    };
    ShowMorePosts();
  }

  render() {
    const posts = this.props.data.allMarkdownRemark.edges;

    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage - 1 === 1
        ? "/blog/"
        : "/blog/page/" + (currentPage - 1).toString();

    const nextPage = "/blog/page/" + (currentPage + 1).toString();

    return (
      <Layout>
        <Helmet>
          <title>
            {"Webdevolution Blog Posts: Page " +
              this.props.pageContext.currentPage}
          </title>
          <meta http-equiv="content-language" content="en-us"></meta>
          <meta
            name="description"
            content="All blog posts listed. Posts about Web development, Javascript, React, Flutter, CSS, C# and more!"
          ></meta>
          <meta name="author" content="Henri Parviainen" />
        </Helmet>
        {!this.state.loading ? (
          <div className={styles.listContainerBlogList}>
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              return (
                <Link key={node.fields.slug} to={`/blog/${node.fields.slug}/`}>
                  <div
                    className={styles.postCardBlogList}
                    key={node.fields.slug}
                  >
                    <h2 className={styles.postHeaderBlogList}>{title}</h2>
                    <p className={styles.dateBlogList}>
                      <time datetime={node.frontmatter.isodate}>
                        {node.frontmatter.date}
                      </time>
                    </p>
                    <p className={styles.pBlogList}>
                      {node.frontmatter.description}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {!this.state.loading ? (
          <div className={styles.navigationContainerBlogList}>
            {!isFirst && (
              <Link
                className={`${styles.paginationNavLinkBlogList}
                ${styles.paginationNavLinkBlogListNew}`}
                to={prevPage}
                rel="prev"
              >
                <span>←</span> Newer Posts
              </Link>
            )}
            {!isLast && (
              <Link
                className={`${styles.paginationNavLinkBlogList}
                ${styles.paginationNavLinkBlogListOld}`}
                to={nextPage}
                rel="next"
              >
                Older Posts <span>→</span>
              </Link>
            )}
          </div>
        ) : null}
      </Layout>
    );
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___isodate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            isodate
          }
        }
      }
    }
  }
`;
