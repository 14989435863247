// extracted by mini-css-extract-plugin
export var listContainerBlogList = "blogList-module--listContainerBlogList--q3FFJ";
export var postCardBlogList = "blogList-module--postCardBlogList--6gnye";
export var fadeIn = "blogList-module--fadeIn--Iu9n9";
export var postHeaderBlogList = "blogList-module--postHeaderBlogList--0PQMx";
export var pBlogList = "blogList-module--pBlogList--ZqDkC";
export var dateBlogList = "blogList-module--dateBlogList--YN7da";
export var navigationContainerBlogList = "blogList-module--navigationContainerBlogList--kDKdq";
export var paginationNavLinkBlogList = "blogList-module--paginationNavLinkBlogList--nbXk3";
export var ldsEllipsis = "blogList-module--ldsEllipsis--rsLYb";
export var ldsEllipsis1 = "blogList-module--ldsEllipsis1--f+wFr";
export var ldsEllipsis2 = "blogList-module--ldsEllipsis2--A6ixd";
export var ldsEllipsis3 = "blogList-module--ldsEllipsis3--4xAJW";